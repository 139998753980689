import * as React from "react"

import { AppFunctions } from "@others/AppFunctions.ts"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useTranslation } from "react-i18next"
import { AppNotification } from "@others/AppNotification.ts"
import { AppLocalStorageKeys } from "@constants/AppLocalStorageKeys.ts"

import membershipService from "@services/membershipService.ts"

interface IOutput {
	pay: ({ country_code }: { country_code: string }) => void
	paymentLoading: boolean
}

export const useMembershipPayment = (): IOutput => {
	const { t } = useTranslation()
	const [paymentLoading, setPaymentLoading] = React.useState(false)

	const pay: IOutput["pay"] = ({ country_code }) => {
		setPaymentLoading(true)

		const back_url =
			AppFunctions.getCurrentDomain() + AppRoutes.membershipPayment

		const success_url =
			AppFunctions.getCurrentDomain() + AppRoutes.userProfile

		membershipService
			.pay({
				back_url,
				success_url,
				country_code,
			})
			.then(({ checkoutUrl, paymentRef, paymentGateway }) => {
				let paymentError: boolean = false

				if (!checkoutUrl) {
					paymentError = true
					console.error(
						`The checkoutUrl=${checkoutUrl} is not a valid url`
					)
				}

				if (!paymentRef) {
					paymentError = true
					console.error(
						`The paymentRef=${paymentRef} is not a valid value`
					)
				}

				if (!paymentGateway) {
					paymentError = true
					console.error(
						`The paymentGateway=${paymentGateway} is not a valid value`
					)
				}

				if (paymentError) {
					AppNotification.error({
						message: t("something_went_wrong"),
					})
				} else {
					// Stored to get payment status the next time the user go to the profile page
					localStorage.setItem(
						AppLocalStorageKeys.PAYMENT_REF,
						paymentRef!
					)

					// Stored to get payment status the next time the user go to the profile page
					localStorage.setItem(
						AppLocalStorageKeys.PAYMENT_GATEWAY,
						paymentGateway!
					)

					window.location.replace(checkoutUrl!)
				}
			})
			.catch(({ message }) => {
				AppNotification.error({
					message: message || t("something_went_wrong"),
				})
			})
			.finally(() => {
				setPaymentLoading(false)
			})
	}

	return { pay, paymentLoading }
}
