import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipPayment.module.scss"

import { usePlanData } from "@hooks/usePlanData.tsx"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { AppNotification } from "@others/AppNotification.ts"
import { usePayMembershipSupportedCountriesData } from "@hooks/usePayMembershipSupportedCountriesData.tsx"
import { useMembershipPayment } from "@hooks/useMembershipPayment.tsx"

import useAuth from "@contexts/hooks/useAuth.tsx"
import PlanDetailItem from "@components/dataEntry/MembershipKindOfForm/PlanDetailItem/PlanDetailItem.tsx"
import DvSelect from "@components/dataEntry/DvSelect/DvSelect.tsx"
import validationRules from "@others/validationRules.ts"
import DvAntdFormItem from "@components/others/DvAntdFormItem/DvAntdFormItem.tsx"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import MembershipFormFooter from "@components/layout/MembershipFormFooter/MembershipFormFooter.tsx"
import DvConfirmationModal from "@components/feedback/DvConfirmationModal/DvConfirmationModal.tsx"
import RedirectConfirmationMessage from "@pages/MembershipPayment/RedirectConfirmationMessage/RedirectConfirmationMessage.tsx"

interface IMembershipPaymentFormFieldType {
	countryCode?: string
}

interface IProps {
	className?: string
}

const MembershipPayment: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const membershipPayment = useMembershipPayment()
	const countryCodeRef = React.useRef<string | undefined>()

	const [form] = Antd.Form.useForm<IMembershipPaymentFormFieldType>()
	const { t } = useTranslation()
	const { loggedUser } = useAuth()
	const { planData, loading: planDataLoading } = usePlanData()

	const { supportedCountries, supportedCountriesLoading } =
		usePayMembershipSupportedCountriesData()

	const [
		externalPaymentConfirmationModalOpen,
		setExternalPaymentConfirmationModalOpen,
	] = React.useState(false)

	const monthlyBill = React.useMemo(
		() =>
			planData?.plans.find((plan) => plan.id === loggedUser?.plan)
				?.monthlyBill,
		[planData, loggedUser]
	)

	const annualBill = React.useMemo(
		() =>
			planData?.plans.find((plan) => plan.id === loggedUser?.plan)
				?.annualBill,

		[planData, loggedUser]
	)

	const initialValues: IMembershipPaymentFormFieldType = React.useMemo(
		() => ({
			countryCode: supportedCountries?.find(
				({ value }) => loggedUser?.countryCode === value
			)?.value,
		}),
		[loggedUser, supportedCountries]
	)

	const onFinish = async ({
		countryCode,
	}: IMembershipPaymentFormFieldType) => {
		countryCodeRef.current = countryCode
		setExternalPaymentConfirmationModalOpen(true)
	}

	React.useLayoutEffect(() => {
		if (loggedUser?.hasAnPaidMembership) {
			navigate(AppRoutes.userProfile, { replace: true })
		}

		if (loggedUser && !planDataLoading && !supportedCountriesLoading) {
			let dataError: boolean = false

			if (!monthlyBill) {
				dataError = true
				console.error(
					`We couldn't find the the monthlyBill of the plan=${loggedUser?.plan} in planData?.plans`
				)
			}

			if (!annualBill) {
				dataError = true
				console.error(
					`We couldn't find the the annualBill of the plan=${loggedUser?.plan} in planData?.plans`
				)
			}

			if (
				loggedUser?.paymentFrequency !== "annual" &&
				loggedUser?.paymentFrequency !== "monthly"
			) {
				dataError = true
				console.error(
					`The paymentFrequency=${loggedUser?.paymentFrequency} is not a valid value ["annual", "monthly"]`
				)
			}

			if (
				loggedUser?.paymentFrequency === "monthly" &&
				loggedUser?.firstPayment !== true &&
				loggedUser?.firstPayment !== false
			) {
				dataError = true
				console.error(
					`The firstPayment=${loggedUser?.firstPayment} is not a valid value [true, false]`
				)
			}

			if (!supportedCountries?.length) {
				dataError = true
				console.error(
					`We couldn't find the supportedCountries=${supportedCountries}`
				)
			}

			if (dataError) {
				AppNotification.error({
					message: t("something_went_wrong"),
				})

				navigate(AppRoutes.userProfile, { replace: true })
			}
		}
	}, [
		planData,
		planDataLoading,
		loggedUser,
		supportedCountries,
		supportedCountriesLoading,
	])

	React.useEffect(() => {
		if (!membershipPayment.paymentLoading) {
			setExternalPaymentConfirmationModalOpen(false)
		}
	}, [membershipPayment.paymentLoading])

	return (
		<>
			<div
				className={`${styles.membership_payment} ${props.className || ""}`}
			>
				<h1 className={styles.title}>{t("membership_payment")}</h1>

				{!planDataLoading && !supportedCountriesLoading ? (
					<>
						<h3 className={styles.sub_title}>
							{loggedUser?.paymentFrequency === "monthly"
								? t("summary_of_your_monthly_payment_method")
								: t("summary_of_your_annual_payment_method")}
							:
						</h3>

						<div className={styles.payment_details}>
							{loggedUser?.paymentFrequency === "monthly" &&
								monthlyBill && (
									<>
										<PlanDetailItem
											title={t("payment_1")}
											subtitle={t(
												"monthly_plus_access_fee"
											)}
											currency="US$"
											value={monthlyBill.firstPayment}
										/>

										<PlanDetailItem
											title={t("payment_2_12")}
											currency="US$"
											value={
												monthlyBill.remainingPayments
											}
											unit="c/u"
											strongBorder
										/>

										<PlanDetailItem
											title={t("current_payment")}
											currency="US$"
											value={
												loggedUser?.firstPayment
													? monthlyBill.firstPayment
													: monthlyBill.remainingPayments
											}
											strongText
										/>
									</>
								)}

							{loggedUser?.paymentFrequency === "annual" &&
								annualBill && (
									<>
										<PlanDetailItem
											title={t("annuity")}
											currency="US$"
											value={annualBill.annuity}
										/>

										<PlanDetailItem
											title={t("access_fee")}
											currency="US$"
											value={annualBill.accessFee}
											strongBorder
										/>

										<PlanDetailItem
											title={t("total")}
											currency="US$"
											value={annualBill.total}
											strongText
										/>
									</>
								)}
						</div>

						<div className={styles.country_selection}>
							<h1 className={styles.text}>
								{t("message_select_country_of_payment_method")}:
							</h1>

							<DvAntdForm<IMembershipPaymentFormFieldType>
								form={form}
								onFinish={onFinish}
								initialValues={initialValues}
								className={styles.country_form}
							>
								<DvAntdFormItem<IMembershipPaymentFormFieldType>
									label={t("country")}
									name="countryCode"
									rules={[
										validationRules.requiredFieldRule(
											t(
												"please_enter_your_country_region"
											)
										),
									]}
								>
									<DvSelect options={supportedCountries} />
								</DvAntdFormItem>
							</DvAntdForm>
						</div>

						<MembershipFormFooter
							backButtonHidden
							nextButtonTest={t("go_to_pay")}
							onNextClick={() => form.submit()}
						/>
					</>
				) : (
					<Antd.Spin />
				)}
			</div>

			<DvConfirmationModal
				className={styles.confirmation_modal}
				open={externalPaymentConfirmationModalOpen}
				width={342}
				loading={membershipPayment.paymentLoading}
				onCancel={() => {
					setExternalPaymentConfirmationModalOpen(false)
				}}
				onOk={() => {
					membershipPayment.pay({
						country_code: countryCodeRef.current!,
					})
				}}
			>
				<RedirectConfirmationMessage />
			</DvConfirmationModal>
		</>
	)
}

export default React.memo(MembershipPayment)
