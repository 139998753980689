export const Endpoints = {
	login: "/auth/login/",
	refreshToken: "/auth/refresh-token/",
	checkEmail: "/auth/check-email/",
	signUp: "/auth/signup/",
	user: "/user/edit/",
	plans: "/membership/plans/",
	applyMembership: "/membership/apply/",
	payMembership: "/membership/payment/",
	payMembershipSupportedCountries: "/membership/payment/supported-countries/",
	payMembershipStatus: "/membership/payment/status/",
}
