import {
	MEMBERSHIP_STEP1_TABS,
	MEMBERSHIP_STEP2_TABS,
	MEMBERSHIP_STEP3_TABS,
	MEMBERSHIP_STEP4_TABS,
	MEMBERSHIP_STEP5_TABS,
} from "@pages/MembershipFormSteps/MembershipFormSteps.constants.ts"

export const AppRoutes = {
	public: "/assets",
	home: "/",
	login: "/login",
	signup: "/login/signup",
	validateAccount: "/login/validate-account",
	info: "/info",
	infoDataCreateAccountReceived: "/info/data-create-account-received",
	infoAccountActivated: "/info/account-activated",
	membership: "/membership",
	membershipPlans: "/membership/plans",
	membershipStartApplying: "/membership/start-applying",
	membershipFormStep1: "/membership/form-step-1",
	membershipFormStep1_details: `/membership/form-step-1/${MEMBERSHIP_STEP1_TABS.details}`,
	membershipFormStep1_address: `/membership/form-step-1/${MEMBERSHIP_STEP1_TABS.address}`,
	membershipFormStep1_kind_of_membership: `/membership/form-step-1/${MEMBERSHIP_STEP1_TABS.kindOfMembership}`,
	membershipFormStep2: "/membership/form-step-2",
	membershipFormStep2_personalDetails: `/membership/form-step-2/${MEMBERSHIP_STEP2_TABS.personalDetails}`,
	membershipFormStep2_job: `/membership/form-step-2/${MEMBERSHIP_STEP2_TABS.job}`,
	membershipFormStep3: "/membership/form-step-3",
	membershipFormStep3_presentation: `/membership/form-step-3/${MEMBERSHIP_STEP3_TABS.presentation}`,
	membershipFormStep3_digital_profiles: `/membership/form-step-3/${MEMBERSHIP_STEP3_TABS.digitalProfiles}`,
	membershipFormStep3_references: `/membership/form-step-3/${MEMBERSHIP_STEP3_TABS.references}`,
	membershipFormStep4: "/membership/form-step-4",
	membershipFormStep4_globoRules: `/membership/form-step-4/${MEMBERSHIP_STEP4_TABS.globoRules}`,
	membershipFormStep5: "/membership/form-step-5",
	membershipFormStep5_sendMembership: `/membership/form-step-5/${MEMBERSHIP_STEP5_TABS.sendMembership}`,
	membershipSuccess: "/membership/success",
	membershipPayment: "/membership/payment",
	user: "/user",
	userProfile: "/user/profile",
	userSettings: "/user/settings",
	userPersonalDataForm: "/user/personal-data-form",
}
