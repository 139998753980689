import { generateAppEntity } from "@others/generateAppEntity.ts"

export const AppLocalStorageKeys = {
	TOKEN: generateAppEntity("token"),
	REFRESH_TOKEN: generateAppEntity("refreshToken"),
	REMEMBER_ME: generateAppEntity("rememberMe"),
	TOKEN_EXPIRATION_TIME: generateAppEntity("tokenExpirationTime"),
	PAYMENT_REF: generateAppEntity("paymentRef"),
	PAYMENT_GATEWAY: generateAppEntity("paymentGateway"),
}
