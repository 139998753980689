import * as React from "react"
import { IGetPayMembershipSupportedCountriesResponseCli } from "@interfaces/IGetPayMembershipSupportedCountriesResponseCli.ts"
import membershipService from "@services/membershipService.ts"

export const usePayMembershipSupportedCountriesData = () => {
	const [supportedCountries, setSupportedCountries] =
		React.useState<IGetPayMembershipSupportedCountriesResponseCli>()

	const [supportedCountriesLoading, setSupportedCountriesLoading] =
		React.useState<boolean>(true)

	const fetchSupportedCountries = React.useCallback(() => {
		setSupportedCountriesLoading(true)

		membershipService
			.paySupportedCountries()
			.then((supportedCountries) => {
				setSupportedCountries(supportedCountries)
			})
			.finally(() => {
				setSupportedCountriesLoading(false)
			})
	}, [])

	React.useEffect(() => {
		fetchSupportedCountries()
	}, [])

	return {
		supportedCountries,
		supportedCountriesLoading,
	}
}
